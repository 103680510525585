import { gql } from 'graphql-request';

export const UPDATE_CLIENT_FILE_INFO_QUERY = gql`
  mutation UpdateClientFileInfo(
    $uuid: ID!
    $async: Boolean
    $versions: [String!]
  ) {
    updateClientFileInfo(
      input: { uuid: $uuid, async: $async, versions: $versions }
    ) {
      status
      errors {
        fullMessages
      }
    }
  }
`;
